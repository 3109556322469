import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { translateEntity } from 'localization';
import { Entity } from 'metadata';
import { RootState } from 'store';
import { api } from './api';

// State
export type MetadataState = Array<Entity>;

const initialState: MetadataState = [];

// Slice
const slice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    set(state: MetadataState, action: PayloadAction<Array<Entity>>): void {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Set metadata after api fetch was successful.
    builder.addMatcher(api.endpoints.getMetadata.matchFulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export const { reducer } = slice;

// Selectors
export const selectMetadata = createSelector(
  (state: RootState) => state.metadata,
  (metadata) => metadata
);

export const selectTranslatedMetadata = createSelector(
  (state: RootState) => state.metadata,
  (metadata) => metadata.map((e) => translateEntity(e))
);
