import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Box, Grid, TextField } from '@material-ui/core';
import FieldRequirement from 'components/FieldRequirement';
import { defaultPasswordRequirements, PasswordRequirements } from 'auth';

interface Props {
  control: Control;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label: string;
  name: string;
  maxLength?: number;
  required?: boolean;
  requirements?: PasswordRequirements;
}

interface SecurityLevel {
  id: string;
  label: string;
  validator: RegExp;
}

const PasswordField: React.FC<Props> = ({
  control,
  disabled,
  error,
  helperText,
  label,
  name,
  maxLength,
  required,
  requirements = defaultPasswordRequirements,
}: Props) => {
  const { t } = useTranslation('defaults');
  const [password, setPassword] = useState<string>('');

  // Password security requirements
  const securityLevels: Array<SecurityLevel> = [];
  if (requirements.requiredLength > 0) {
    securityLevels.push({
      id: 'requiredLength',
      label: t('fieldRequirements.password.requiredLength', {
        length: requirements.requiredLength,
      }),
      validator: new RegExp(`^.{${requirements.requiredLength},}$`, 'g'),
    });
  }
  if (requirements.requireUppercase) {
    securityLevels.push({
      id: 'requireUppercase',
      label: t('fieldRequirements.password.requireUppercase'),
      validator: /.*[A-Z].*/,
    });
  }
  if (requirements.requireLowercase) {
    securityLevels.push({
      id: 'requireLowercase',
      label: t('fieldRequirements.password.requireLowercase'),
      validator: /.*[a-z].*/,
    });
  }
  if (requirements.requireDigit) {
    securityLevels.push({
      id: 'requireDigit',
      label: t('fieldRequirements.password.requireDigit'),
      validator: /.*[0-9].*/,
    });
  }
  if (requirements.requireNonAlphanumeric) {
    securityLevels.push({
      id: 'requireNonAlphanumeric',
      label: t('fieldRequirements.password.requireNonAlphanumeric'),
      validator: /.*[^A-Za-z\d\s].*/,
    });
  }

  return (
    <>
      <Box pb={1}>
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field, fieldState }) => (
            <TextField
              label={label}
              disabled={disabled}
              error={fieldState.invalid || error}
              helperText={helperText}
              size="small"
              variant="outlined"
              type="password"
              required
              fullWidth
              inputProps={{ maxLength }}
              {...field}
              onChange={(e) => {
                setPassword(e.target.value);
                field.onChange(e);
              }}
            />
          )}
        />
      </Box>
      <Grid container spacing={1}>
        {securityLevels
          .filter((level) => !level.validator.test(password))
          .map((level) => (
            <Grid key={level.id} item xs={12}>
              <FieldRequirement
                label={level.label}
                valid={password != '' ? level.validator.test(password) : undefined}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default PasswordField;
