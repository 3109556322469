import axios from 'axios';
import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, ofType } from 'redux-observable';
import { filter, ignoreElements, map, tap, Observable } from 'rxjs';
import { api } from './api';
import { actions } from './slice';
// import { deleteStorageToken, setStorageToken } from './utils';

export const onLoginOrRefresh = (action$: Observable<AnyAction>): Observable<AnyAction> =>
  action$.pipe(
    filter(
      (action) =>
        api.endpoints.login.matchFulfilled(action) ||
        api.endpoints.refreshToken.matchFulfilled(action)
    ),
    map((action) => actions.setCredentials(action.payload))
  );

export const onChangeCredentials = (action$: Observable<AnyAction>): Observable<AnyAction> =>
  action$.pipe(
    ofType(actions.logout.toString(), actions.setCredentials.toString()),
    tap((action) => {
      if (action.payload?.accessToken) {
        // Persist storage token
        // TODO: Persist a refresh token only, not a JWT token because it could expose user information
        //       like email address if the user doesn't log out correctly.
        //       Consider to implement a short lifetime refresh token which can be stored in a cookie or local storage.
        // setStorageToken(action.payload.accessToken);

        // Set authorization for axios requests
        // TODO: Should not be set globally, because this could expose token to other apis
        axios.defaults.headers.common.Authorization = `Bearer ${action.payload.accessToken}`;
      } else {
        // Remove storage token and authorization headers
        // deleteStorageToken();
        delete axios.defaults.headers.common.Authorization;
      }
    }),
    ignoreElements()
  );

export const epic = combineEpics(onChangeCredentials, onLoginOrRefresh);
