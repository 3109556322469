import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ApiError, StringMap } from 'types';

interface ErrorProps {
  elevation?: number;
  error?: StringMap | ApiError | string;
}

interface ErrorItem {
  id: string;
  title?: string;
  message: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      borderRadius: '0',
    },
  })
);

const Error = ({ elevation = 1, error }: ErrorProps): React.ReactElement | null => {
  const classes = useStyles();
  const { t } = useTranslation('Error');

  // Get error items
  let items: Array<ErrorItem> = [];
  if (error && error.hasOwnProperty('title') && error.hasOwnProperty('status')) {
    const apiError = error as ApiError;
    items.push({ id: '1', title: `${t('title')} ${apiError.status}`, message: apiError.title });
  } else if (typeof error === 'string') {
    items.push({ id: '1', title: t('title'), message: error });
  } else if (error) {
    items = Object.entries(error).map(([k, v]) => ({ id: k, title: t('title'), message: v }));
  }

  return items.length > 0 ? (
    <Paper elevation={elevation} role="alert">
      {items.map((item) => (
        <Alert key={item.id} className={classes.alert} severity="error">
          <AlertTitle>{item.title}</AlertTitle>
          {item.message}
        </Alert>
      ))}
    </Paper>
  ) : null;
};

export default Error;
