import React from 'react';
import PropTypes from 'prop-types';
import { Control, Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core';

export interface SwitchMenuItem {
  label: string;
  value: string;
}

interface SwitchProps {
  control?: Control;
  defaultValue?: any;
  disabled?: boolean;
  error?: any;
  label: string;
  name: string;
  required?: boolean;
  props?: MuiSwitchProps;
}

export function Switch({
  control,
  defaultValue,
  disabled,
  error,
  label,
  name,
  props,
  required,
}: SwitchProps): React.ReactElement {
  const renderSwitch = ({ field }: any): JSX.Element => (
    <MuiSwitch
      id={name}
      name={name}
      checked={field && field.value}
      onChange={(e) => field && field.onChange(e.target.checked)}
      required={required}
      {...props}
      {...field}
    />
  );

  // Material UI Switch and some other components don't work with react-hook-form (uncontrolled inputs),
  // that's why we use a react-hook-form controller instead the register method
  // Find more information here:
  //   https://github.com/react-hook-form/react-hook-form/issues/380
  //   https://stackoverflow.com/questions/63236951/how-to-use-material-ui-select-with-react-hook-form
  //   https://codesandbox.io/s/react-hook-form-v6-controller-qsd8r?file=/src/Mui.js
  return (
    <FormControl error={error} fullWidth variant="outlined">
      <InputLabel id={name} disabled={disabled} required={required}>
        {label}
      </InputLabel>
      {control ? (
        <Controller
          control={control}
          defaultValue={defaultValue || false}
          name={name}
          rules={{ required }}
          render={renderSwitch}
        />
      ) : (
        renderSwitch({ field: {}, fieldState: {} })
      )}
    </FormControl>
  );
}

Switch.propTypes = {
  control: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  props: PropTypes.any,
  value: PropTypes.any,
};

export default Switch;
