import jwtDecode from 'jwt-decode';
import { User } from './types';

/**
 * Checks if the specified user has the requested permission.
 * @param user The user to check permission.
 * @param permission that is requested.
 * @returns True if user has the requested permission.
 */
export function hasPermission(user: User | null, permission?: string): boolean {
  return (
    (user &&
      user.permissions &&
      (permission == undefined || user.permissions.includes(permission))) ||
    false
  );
}

/**
 * Checks if the specified user has the requested role assigned.
 * @param user The user to check for a role.
 * @param role that is requested.
 * @returns True if user has the requested role assigned.
 */
export function hasRole(user?: User | null, role?: string): boolean {
  return (user && user.roles && (role == undefined || user.roles.includes(role))) || false;
}

/**
 * Delete the authentication token from local storage.
 */
export function deleteStorageToken(): void {
  localStorage.removeItem('auth.token');
}

/**
 * Gets the authentication token from local storage.
 */
export function getStorageToken(): string | null {
  return localStorage.getItem('auth.token');
}

/**
 * Sets the authentication token to local storage.
 */
export function setStorageToken(token: string): void {
  localStorage.setItem('auth.token', token);
}

/**
 * Returns true if the specified token is valid.
 */
export function isValidToken(accessToken: string): boolean {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
}

/**
 * Sets the authentication headers if available.
 * @param headers request headers.
 * @returns request headers with authentication set if available.
 */
export function authHeaders(headers: Headers): Headers {
  const token = getStorageToken();
  if (token) {
    headers.set('authentication', `Bearer ${token}`);
  }
  return headers;
}
