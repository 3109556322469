import * as Metadata from 'metadata';
import { StringMap } from 'types';

export interface EntityRouteParams {
  key?: string;
  collection?: string;
}

/**
 * Extracts the entity route params from the specified object.
 * @param params The params to extract relevant properties from.
 * @param metadata Entity metadata.
 */
export function getEntityRouteValues(
  params: StringMap,
  metadata: Metadata.Entity
): EntityRouteParams {
  const keyName = metadata.commands.find(
    (c) => c.name === 'edit' && c.scope === Metadata.CommandScope.Entity
  )?.key?.name;

  return params
    ? {
        key: keyName ? params[keyName] : undefined,
        collection: params['collection'],
      }
    : {};
}
