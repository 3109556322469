import React from 'react';
export default function html(): JSX.Element {
  return (
    <div>
      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Norman Geiersbach
        <br />
        Eitido
        <br />
        Französische Straße 12
        <br />
        10117 Berlin
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 30 20 188 318
        <br />
        Telefax: +49 30 20 188 575
        <br />
        E-Mail: hallo@eitido.de
      </p>

      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
        <br />
        DE 277 806 375
      </p>
    </div>
  );
}
