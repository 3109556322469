import React from 'react';
import RouteContext from 'contexts/RouteContext';

/**
 * `useRouteContext` (react-hook) returns the `context` provided to `RouteContextProvider` *
 * @remarks
 * Type parameter T will not be validated.
 */
export const useRouteContext = <T extends Record<string, any>>(): T | null => {
  const { context } = React.useContext(RouteContext);

  // TODO: Validate context type T
  // TODO: Merge / extend nested contexts

  return context;
};
