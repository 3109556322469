import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useAuth } from 'auth';

const Welcome: React.FC = () => {
  const { t } = useTranslation('Home');
  const { user } = useAuth();

  return (
    user && (
      <Box pt={1}>
        <Typography variant="body1">{t('welcome', { userName: user.userName })}</Typography>
      </Box>
    )
  );
};

Welcome.propTypes = {};

export default Welcome;
