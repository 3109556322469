import { unflatten } from 'flat';
import i18n from 'i18next';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { api } from './api';
import { StringMap } from 'types';

// State
const initialState: StringMap = {};

// Slice
const slice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    set(state: StringMap, action: PayloadAction<StringMap>): void {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Set locales after api fetch was successful.
    builder.addMatcher(api.endpoints.getLocales.matchFulfilled, (state, { payload }) => {
      // TODO: Move code to async thunk and load data for all supported languages `i18n.options.supportedLngs`
      const unflattened = unflatten(payload) as StringMap;
      for (const [lng, value] of Object.entries(unflattened)) {
        i18n.addResourceBundle(lng, 'metadata', value);
      }
      return unflattened as StringMap;
    });
  },
});

export const { reducer } = slice;

// Selectors
export const selectLocalization = createSelector(
  (state: RootState) => state.localization,
  (localization) => localization
);
