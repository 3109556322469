import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Container } from '@material-ui/core';

import Header from 'components/Header';
import Tabs from 'components/Tabs';
import { combinePath } from 'routes';
import { TabItem } from 'types';

import Account from './Account';
import Security from './Security';
import Theme from './Theme';

const SettingsView: React.FC = () => {
  const { t } = useTranslation('account');
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();
  const tabs: Array<TabItem> = [
    {
      label: t('Account.title'),
      value: '',
    },
    {
      label: t('Security.title'),
      value: 'security',
    },
    {
      label: t('Theme.title'),
      value: 'theme',
    },
  ];

  const currentTab =
    location.pathname.length + 1 > routeMatch.path.length
      ? location.pathname.substr(routeMatch.path.length + 1)
      : '';

  const changeTab = (e: any, newValue: string): void => {
    history.push(combinePath(routeMatch.path, newValue));
  };

  return (
    <>
      <Container disableGutters maxWidth="md">
        <Header title={t('Settings.title')} />
        <Tabs tabs={tabs} value={currentTab} onChange={changeTab} />
        <Switch>
          <Route path={combinePath(routeMatch.path, '')} exact>
            <Account />
          </Route>
          <Route path={combinePath(routeMatch.path, 'security')}>
            <Security />
          </Route>
          <Route path={combinePath(routeMatch.path, 'theme')}>
            <Theme />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default SettingsView;
