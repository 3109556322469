import React from 'react';
export default function html(): JSX.Element {
  return (
    <div>
      <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
      <p>
        Norman Geiersbach
        <br />
        Eitido
        <br />
        Französische Straße 12
        <br />
        10117 Berlin
      </p>

      <h2>Contact</h2>
      <p>
        Phone: +49 30 20 188 318
        <br />
        Telefax: +49 30 20 188 575
        <br />
        E-mail: hallo@eitido.de
      </p>

      <h2>VAT ID</h2>
      <p>
        Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
        <br />
        DE 277 806 375
      </p>
    </div>
  );
}
