import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import application from 'application';
import { translateFeatureTitle, translateModuleTitle } from 'localization';
import DefaultLayout from 'layouts/DefaultLayout';
import { errorPath, featurePath } from './path';
import { AppRoute } from './Route';
import { Application, Module } from 'types';

import AccountSettings from 'views/Account/Settings';
import Home from 'views/Home';
import Imprint from 'views/Imprint';
import PrivacyPolicy from 'views/PrivacyPolicy';

export function createModuleRoutes(module: Module, baseUrl = ''): Array<AppRoute> {
  return module.features.map((feature) => ({
    path: featurePath(module.name, feature.name, baseUrl),
    component: feature.component,
    layout: feature.layout || module.layout,
    context: {
      module: module.name,
      feature: feature.name,
    },
    title: `${translateFeatureTitle(module.name, feature.name)} | ${translateModuleTitle(
      module.name
    )}`,
    role: feature.role || module.role,
  }));
}

export function createAppRoutes(app: Application): Array<AppRoute> {
  return app.modules.flatMap((module) => createModuleRoutes(module, app.baseUrl));
}

const basicRoutes: Array<AppRoute> = [
  {
    exact: true,
    path: '/(dashboard)?',
    component: Home,
    layout: DefaultLayout,
    context: { module: 'Dashboard' },
    title: translateModuleTitle('Dashboard'),
  },
  {
    exact: false,
    path: '/account',
    component: AccountSettings,
    layout: DefaultLayout,
    context: { module: 'Account' },
    title: translateModuleTitle('Account'),
  },
  {
    exact: true,
    path: '/imprint',
    component: Imprint,
    layout: DefaultLayout,
    context: { module: 'Imprint' },
    title: translateModuleTitle('Imprint'),
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
    layout: DefaultLayout,
    context: { module: 'Privacy' },
    title: translateModuleTitle('Privacy'),
  },
];

const fallbackRoutes: Array<AppRoute> = [
  {
    /* eslint-disable-next-line react/display-name */
    component: (): ReactElement => <Redirect to={errorPath(404)} />,
    allowAnonymous: true,
    context: {},
  },
];

export const appRoutes: Array<AppRoute> = [
  ...createAppRoutes(application),
  ...basicRoutes,
  ...fallbackRoutes,
];
