import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

const ComponentError = ({ error, resetErrorBoundary }: FallbackProps): React.ReactElement => {
  const { t } = useTranslation('ComponentError');
  return (
    <Box pt={4} pr={9} pl={9} role="alert">
      <Box pb={1}>
        <Typography variant="h1">{t('title')}</Typography>
      </Box>
      <Box pb={4}>
        <Typography>{error.message}</Typography>
      </Box>
      <Button color="primary" variant="contained" onClick={resetErrorBoundary}>
        {t('tryAgain')}
      </Button>
    </Box>
  );
};

export default ComponentError;
