import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Action } from 'types';
import Icon from './Icon';

type ActionItemProps = Action;

export function ActionItem({
  badge,
  edge,
  download,
  href,
  icon,
  label,
  target,
  onClick,
}: ActionItemProps): React.ReactElement {
  const linkProps = {
    component: href ? 'a' : undefined,
    href: href ? href : undefined,
    download: download ? download : undefined,
    target: target || (href && !download ? '_blank' : undefined),
  };

  return (
    <Tooltip title={label} enterDelay={300}>
      <IconButton aria-label={label} color="inherit" edge={edge} onClick={onClick} {...linkProps}>
        <Icon badge={badge} icon={icon} />
      </IconButton>
    </Tooltip>
  );
}

export default ActionItem;
