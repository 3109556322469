import { Entity } from './entity';
import { DataType, FormState, TypeCode } from './enum';

export type JsType = 'boolean' | 'number' | 'object' | 'string';
export type YupType = JsType | 'date' | 'undefined';

// Returns the javascript type for the specified type code.
export function getJsType(code: TypeCode): JsType | 'undefined' {
  switch (code) {
    case TypeCode.Boolean:
      return 'boolean';
    case TypeCode.Char:
    case TypeCode.String:
      return 'string';
    case TypeCode.DateTime:
    case TypeCode.DBNull: // null equivalent
    case TypeCode.Empty: // null equivalent
    case TypeCode.Object:
      return 'object';
    case TypeCode.Byte:
    case TypeCode.Decimal:
    case TypeCode.Double:
    case TypeCode.Int16:
    case TypeCode.Int32:
    case TypeCode.Int64:
    case TypeCode.SByte:
    case TypeCode.Single:
    case TypeCode.UInt16:
    case TypeCode.UInt32:
    case TypeCode.UInt64:
      return 'number';
    default:
      return 'undefined';
  }
}

// Returns the yup type for the specified type code.
export function getYupType(code: TypeCode): YupType {
  switch (code) {
    case TypeCode.Boolean:
      return 'boolean';
    case TypeCode.Char:
    case TypeCode.String:
      return 'string';
    case TypeCode.DateTime:
      return 'date';
    case TypeCode.DBNull: // null equivalent
    case TypeCode.Empty: // null equivalent
    case TypeCode.Object:
      return 'object';
    case TypeCode.Byte:
    case TypeCode.Decimal:
    case TypeCode.Double:
    case TypeCode.Int16:
    case TypeCode.Int32:
    case TypeCode.Int64:
    case TypeCode.SByte:
    case TypeCode.Single:
    case TypeCode.UInt16:
    case TypeCode.UInt32:
    case TypeCode.UInt64:
      return 'number';
    default:
      return 'undefined';
  }
}

// Returns the HTML input type for the specified data type.
export function getInputType(
  typeCode: TypeCode,
  dataType?: DataType
): React.InputHTMLAttributes<unknown>['type'] {
  const jsType = getJsType(typeCode);
  switch (jsType) {
    case 'boolean':
      return 'checkbox';
    case 'number':
      return 'number';
    case 'object':
      switch (dataType) {
        case DataType.DateTime:
          return 'datetime-local';
        case DataType.Date:
          return 'date';
        case DataType.Duration:
        case DataType.Time:
          return 'time';
        default:
          return 'text';
      }
      break;
    case 'string':
      switch (dataType) {
        case DataType.Color:
          return 'color';
        case DataType.EmailAddress:
          return 'email';
        case DataType.FileName:
          return 'file';
        case DataType.PhoneNumber:
          return 'tel';
        case DataType.Password:
          return 'password';
        case DataType.Url:
          return 'url';
        default:
          return 'text';
      }
  }

  return 'text';
}

// Returns true if the apply state matches the form state.
export function formStateMatch(applyState: FormState, formState: FormState): boolean {
  return applyState === FormState.All || formState === FormState.All || applyState === formState;
}

/**
 * Returns the specified entity or throws an error.
 * @param entities Array of entity metadata.
 * @param name Name of the resource entity.
 * @returns Entity metadata.
 */
export function findEntity(entities: Array<Entity>, name: string): Entity {
  const entity = entities.find((e) => e.name === name);
  if (!entity) {
    throw Error(`Entity metadata of '${name}' not found.`);
  }
  return entity;
}
