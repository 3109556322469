import { api as mainApi } from 'api';
import * as Metadata from 'metadata';

type MetadataResponse = Array<Metadata.Entity>;

export const api = mainApi.injectEndpoints({
  endpoints: (build) => ({
    getMetadata: build.query<MetadataResponse, void>({
      query: () => 'metadata',
    }),
  }),
});

export const { useGetMetadataQuery } = api;
