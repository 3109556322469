export enum TextAlignment {
  Default = 'default',
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum FieldSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Full = 'full',
}

export enum FormState {
  New = 'new',
  Edit = 'edit',
  All = 'all',
}

export enum CommandScope {
  None = 'none',
  Collection = 'collection',
  Entity = 'entity',
}

export enum DataType {
  // Custom data type
  Custom = 'custom',
  // Enumeration type
  Enumeration = 'enumeration',

  //#region Time specific types
  // Date and time of day
  DateTime = 'dateTime',
  // Date of day
  Date = 'date',
  // Time of day
  Time = 'time',
  // Duration in time
  Duration = 'duration',
  //#endregion

  //#region Number specific types
  // File size
  FileSize = 'fileSize',
  // Monetary data type
  Money = 'money',
  //#endregion

  //#region Text specific types
  // Single line text
  SinglelineText = 'singlelineText',
  // Multiline text
  MultilineText = 'multilineText',
  // String list
  StringList = 'stringList',

  // Telephone number
  PhoneNumber = 'phoneNumber',
  // Email address
  EmailAddress = 'emailAddress',
  // IP address
  IpAddress = 'ipAddress',

  // Password
  Password = 'password',
  // Universally Unique Identifier
  Uuid = 'uuid',
  // Password
  Color = 'color',
  // File name
  FileName = 'fileName',
  // Mime type
  MimeType = 'mimeType',
  // Uniform resource locator
  Url = 'url',

  // Audit log
  AuditLog = 'auditLog',
  // Extended log format (W3C)
  ExtendedLog = 'extendedLog',
  // Log
  Log = 'log',

  // Json markup
  Json = 'json',
  // Html markup
  Html = 'html',
  // Markdown
  Markdown = 'markdown',
  // Xml data
  Xml = 'xml',
  //#endregion
}

export enum UnitType {
  // Custom unit type
  Custom = 'custom',

  //#region Absolute length units
  // Meter (m) is the fundamental unit of length in the International System of Units (SI).
  Meter = 'meter',
  // Kilometer (km). A kilometer represents 1000 m.
  Kilometer = 'kilometer',
  // Centimeter (cm). A centimeter represents 1/100 m.
  Centimeter = 'centimeter',
  // Millimeter (mm). A millimeter represents 1/1000 m.
  Millimeter = 'millimeter',
  // Micrometer (µm). A micrometer represents 1/1000 mm.
  Micrometer = 'micrometer',
  // Nanometer (nm). A nanometer represents 1/1000 µm.
  Nanometer = 'nanometer',
  // Inch (in). A inch represents 2.54 centimeters.
  Inch = 'inch',
  // Foot (ft). A foot represents 12 inches.
  Foot = 'foot',
  // Yard (yd). A yard represents 3 feet.
  Yard = 'yard',
  // Pixel (px). A pixel represents 1/96 of an inch.
  Pixel = 'pixel',
  // Point (pt). A point represents 1/72 of an inch.
  Point = 'point',
  // Pica (pc). A pica represents 12 points.
  Pica = 'Pica',
  //#endregion

  //#region Relative units
  // Percentage (%)
  Percentage = 'percentage',
  // Font size of the element (em)
  FontSizeOfTheElement = 'fontSizeOfTheElement',
  // Font size of the root element (rem)
  FontSizeOfTheRootElement = 'fontSizeOfTheRootElement',
  // X-Height of the element's font (ex)
  FontXHeight = 'fontXHeight',
  //#endregion

  //#region Digital information units
  // Bit (bit).
  Bit = 'bit',
  // Byte (B). A byte represents 8 bits.
  Byte = 'byte',
  // Kilobyte (kB). A kilobyte represents 1024 bytes.
  Kilobyte = 'kilobyte',
  // Megabyte (MB). A megabyte represents 1024 kB.
  Megabyte = 'megabyte',
  // Gigabyte (GB). A gigabyte represents 1024 MB.
  Gigabyte = 'gigabyte',
  // Terabyte (GB). A terabyte represents 1024 GB.
  Terabyte = 'terabyte',
  //#endregion

  //#region Angle units
  // Degree (deg). There are 360 degrees in a full circle.
  Degree = 'degree',
  // Gradian (grad). There are 400 gradians in a full circle.
  Gradian = 'gradian',
  // Radian (rad). There are 2? radians in a full circle.
  Radian = 'radian',
  // Turn (turn). There is 1 turn in a full circle.
  Turn = 'turn',
  //#endregion

  //#region Time units
  // Day (d). A day represents 24 hours.
  Day = 'day',
  // Hour (h). An hour represents 60 minutes.
  Hour = 'hour',
  // Minute (min). A minute represents 60 seconds.
  Minute = 'minute',
  // Second (s) is the base unit of time in the International System of Unit (SI).
  Second = 'second',
  // Millisecond (ms). A millisecond represents 1/1000 s.
  Millisecond = 'millisecond',
  // Microsecond (µs). A microsecond represents 1/1000 ms.
  Microsecond = 'microsecond',
  // Nanosecond (ns). A nanosecond represents 1/1000 µs.
  Nanosecond = 'nanosecond',
  //#endregion

  //#region Frequency units
  // Hertz (Hz) is the SI unit of frequency defined as the number of cycles per second of a periodic phenomenon.
  Hertz = 'hertz',
  // Kilohertz (kHz). A kilohertz represents 1000 Hz.
  Kilohertz = 'kilohertz',
  // Megahertz (MHz). A megahertz represents 1000 kHz.
  Megahertz = 'megahertz',
  // Gigahertz (GHz). A gigahertz represents 1000 MHz.
  Gigahertz = 'gigahertz',
  //#endregion

  //#region Resolution units
  // Dots per inch (dpi)
  DotsPerInch = 'dotsPerInch',
  // Dots per centimeter (dpcm)
  DotsPerCentimeter = 'dotsPerCentimeter',
  // Dots per pixel (dppx)
  DotsPerPixel = 'dotsPerPixel',
  //#endregion
}

export enum TypeCode {
  Boolean = 'boolean',
  Byte = 'byte',
  Char = 'char',
  DateTime = 'dateTime',
  DBNull = 'dBNull',
  Decimal = 'decimal',
  Double = 'double',
  Empty = 'empty',
  Int16 = 'int16',
  Int32 = 'int32',
  Int64 = 'int64',
  Object = 'object',
  SByte = 'sByte',
  Single = 'single',
  String = 'string',
  UInt16 = 'uInt16',
  UInt32 = 'uInt32',
  UInt64 = 'uInt64',
}
