import React, { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { StringMap } from 'types';

interface Props {
  children?: React.ReactNode;
}

interface ComponentState {
  component?: React.ComponentType<StringMap>;
  props: StringMap;
}

export type DialogOpenFn = <T = StringMap>(component: React.ComponentType<T>, props: T) => void;

interface State extends ComponentState {
  open: DialogOpenFn;
  close: () => void;
}

const initialDialogState: State = {
  props: {},
  open: () => {},
  close: () => {},
};

export const DialogContext = createContext<State>(initialDialogState);

export const DialogProvider = ({ children }: Props): JSX.Element => {
  const [state, setState] = useState<ComponentState>(initialDialogState);

  const close = useCallback(() => setState({ props: {} }), []);
  const open = useCallback<DialogOpenFn>((component, props) => {
    setState({ component: component as any, props });
  }, []);

  return (
    <DialogContext.Provider
      value={{
        ...state,
        open,
        close,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node,
};

export default DialogContext;
