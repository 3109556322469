import React from 'react';
import { Box, ListItemText, Menu as MuiMenu, MenuItem, PopoverOrigin } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Action } from 'types';
import { Icon } from './Icon';

interface Props {
  anchorElement?: HTMLElement;
  anchorOrigin?: PopoverOrigin;
  id: string;
  items: Array<Action>;
  open?: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: 4,
    marginBottom: 4,
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

export function ActionMenu({
  anchorElement,
  anchorOrigin,
  id,
  items,
  open,
  onClose,
}: Props): React.ReactElement {
  const classes = useStyles();

  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <>
      <MuiMenu
        anchorEl={anchorElement}
        anchorOrigin={anchorOrigin}
        id={id}
        keepMounted
        transformOrigin={anchorOrigin}
        open={open || false}
        onClose={onClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              onClose && onClose();
              item.onClick && item.onClick();
            }}
          >
            <Box
              className={classes.link}
              {...{
                component: item.href ? 'a' : undefined,
                href: item.href ? item.href : undefined,
                download: item.download ? item.download : undefined,
                target: item.target || (item.href && !item.download ? '_blank' : undefined),
              }}
            >
              {item.icon && (
                <Box className={classes.icon}>
                  <Icon icon={item.icon} />
                </Box>
              )}
              {item.label && item.label !== '' && <ListItemText primary={item.label} />}
            </Box>
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  );
}

export default ActionMenu;
