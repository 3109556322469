import ContentManagement from 'modules/ContentManagement/module';
import Form from 'modules/Form/module';
import Media from 'modules/Media/module';
import Sales from 'modules/Sales/module';
import Administration from 'modules/Administration/module';
import { Application } from 'types';

/**
 * The application catalog with all modules and features.
 */
export const application: Application = {
  name: 'Admin UI',
  baseUrl: '/',
  /*
  Remember this when using dynamic import and React.lazy:
  If we hardcode something like import(“./microFrontendRoutes.js”), it will make this file a separate chunk.
  If we code something like import(“./” + routeMapping[selected.value]), it will make every file in ./ directory a separate chunk.
  If we code something like import(someVariable), Webpack throws an error.
  https://betterprogramming.pub/dynamic-import-code-splitting-lazy-loading-and-error-boundaries-fff57e63f6c4
  */
  // TODO: Consider to use micro frontends for each module
  modules: [ContentManagement, Form, Media, Sales, Administration],
};

export default application;
