import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DefaultLayout from 'layouts/DefaultLayout';

import type { FC } from 'react';
const useStyles = makeStyles(() => ({
  subtitle: {
    whiteSpace: 'pre-line',
  },
}));

const NotFoundView: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('NotFound');

  return (
    <DefaultLayout>
      <Container maxWidth="sm">
        <Card>
          <CardHeader
            title={String(t('title'))}
            titleTypographyProps={{ align: 'center', variant: mobileDevice ? 'h4' : 'h1' }}
          ></CardHeader>
          <CardContent>
            <Typography className={classes.subtitle} align="center" variant="body1">
              {t('subtitle')}
            </Typography>
            <Box mt={4} display="flex" justifyContent="center">
              <Button color="secondary" component={RouterLink} to="/">
                {t('backToHome')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </DefaultLayout>
  );
};

export default NotFoundView;
