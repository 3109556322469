import { useRouteContext } from 'hooks/useRouteContext';
import { AppContext } from 'routes';
import { translateFeatureTitle, translateModuleTitle } from 'localization';

interface BreadcrumbsState {
  crumbs: Array<string>;
}

function useBreadcrumbs(): BreadcrumbsState {
  const context = useRouteContext<AppContext>();
  const crumbs: string[] = [];

  if (context && context.module) {
    crumbs.push(translateModuleTitle(context.module));

    if (context.feature) {
      crumbs.push(translateFeatureTitle(context.module, context.feature));
    }
  }

  return {
    crumbs,
  };
}

export default useBreadcrumbs;
