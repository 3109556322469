import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { initialConfiguration } from 'configuration';
import { Credentials, PasswordRequirements, User } from './types';
import { authHeaders } from './utils';

interface LoginRequest {
  username: string;
  password: string;
}

interface PasswordRequest {
  password: string;
  newPassword: string;
}

interface UpdateUserRequest {
  userName: string;
  email: string;
  phoneNumber: string;
}

export const api = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: initialConfiguration.authEndpoint,
    prepareHeaders: authHeaders,
  }),
  endpoints: (build) => ({
    changePassword: build.mutation<void, PasswordRequest>({
      query: (data) => ({
        url: 'changePassword',
        method: 'POST',
        body: data,
      }),
    }),
    deleteAccount: build.mutation<void, void>({
      query: () => ({
        url: '',
        method: 'DELETE',
      }),
    }),
    getMe: build.query<User, void>({
      query: () => 'me',
    }),
    getPasswordRequirements: build.query<PasswordRequirements, void>({
      query: () => 'passwordRequirements',
    }),
    login: build.mutation<Credentials, LoginRequest>({
      query: (data) => ({
        url: 'login',
        method: 'POST',
        body: data,
      }),
    }),
    refreshToken: build.mutation<Credentials, void>({
      query: () => ({
        url: 'refreshToken',
        method: 'POST',
      }),
    }),
    // registerUser,
    updateUser: build.mutation<Credentials, UpdateUserRequest>({
      query: (data) => ({
        url: 'update',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useDeleteAccountMutation,
  useGetMeQuery,
  useGetPasswordRequirementsQuery,
  useLoginMutation,
  useRefreshTokenMutation,
  // useRegisterUserMutation,
  useUpdateUserMutation,
} = api;
