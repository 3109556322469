import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import DefaultLayout from 'layouts/DefaultLayout';

import DeContent from './de';
import EnContent from './en';

interface Props {
  compact?: boolean;
}

const ImprintView: React.FC<Props> = ({ compact }: Props) => {
  const { t, i18n } = useTranslation('Imprint');
  const Layout = compact ? React.Fragment : DefaultLayout;

  return (
    <Layout>
      <Box pb={compact ? 1 : 3}>
        <PageTitle title={String(t('title'))} overline={String(t('overline'))} />
      </Box>
      {i18n.language === 'de' && <DeContent />}
      {i18n.language === 'en' && <EnContent />}
    </Layout>
  );
};

export default ImprintView;
