import account from './account.json';
import application from './application.json';
import components from './components.json';
import defaults from './defaults.json';
import messages from './messages.json';
import views from './views.json';

export const en = {
  account,
  application,
  ...components,
  defaults,
  messages,
  ...views,
};
