import i18n from 'i18next';
import snakeCase from 'lodash/snakeCase';
import * as Metadata from 'metadata';

// Returns the translated application title, or the name if no translation available.
export function translateApplication(application: string): string {
  return i18n.t(`application:title`, { defaultValue: application });
}

// Returns the translated module title, or the name if no translation available.
export function translateModuleTitle(module: string): string {
  return i18n.t(`application:modules.${module}.title`, { defaultValue: module });
}

// Returns the translated feature title, or the name if no translation available.
export function translateFeatureTitle(module: string, feature: string): string {
  return i18n.t(`application:modules.${module}.features.${feature}`, {
    defaultValue: feature,
  });
}

// Returns a clone of the specified metadata with translations included.
export function translateField(field: Metadata.Field, ns: string): Metadata.Field {
  let translatedDisplayName = undefined;
  if (field.componentType === 'CollectionField') {
    const targetName = (field as Metadata.CollectionField).otherName;
    translatedDisplayName = i18n.t(`metadata:${targetName}.one`, { defaultValue: undefined });
  } else if (field.componentType === 'ReferenceField') {
    const targetName = (field as Metadata.ReferenceField).targetName;
    translatedDisplayName = i18n.t(`metadata:${targetName}.one`, { defaultValue: undefined });
  }

  return Object.assign({}, field, {
    displayName: i18n.t(
      [`metadata:${ns}.properties.${field.name}`, `defaults:properties.${field.name}`],
      { defaultValue: translatedDisplayName || field.displayName }
    ),
    description: i18n.t(
      [
        `metadata:${ns}.propertyDescriptions.${field.name}`,
        `defaults:propertyDescriptions.${field.name}`,
      ],
      { defaultValue: null }
    ),
  });
}

// Returns a clone of the specified metadata with translations included.
export function translateForm(form: Metadata.Form, ns: string): Metadata.Form {
  const translatedFieldsets = form.fieldsets.map((fieldset) => {
    if (fieldset.componentType === 'CollectionFieldset') {
      const collectionFieldset = fieldset as Metadata.CollectionFieldset;
      const displayName = i18n.t(
        [
          `metadata:${ns}.fieldsets.${collectionFieldset.collection.displayCollectionName}`,
          `metadata:${collectionFieldset.collection.key?.targetName}.other`,
        ],
        { defaultValue: collectionFieldset.collection.displayCollectionName }
      );
      return Object.assign({}, fieldset, { displayName });
    }

    if (fieldset.componentType === 'MemberFieldset') {
      const memberFieldset = fieldset as Metadata.MemberFieldset;
      const displayName = i18n.t(
        [
          `metadata:${ns}.fieldsets.${memberFieldset.displayName}`,
          `defaults:fieldsets.${snakeCase(memberFieldset.displayName)}`,
          `defaults:fieldsets.properties`,
        ],
        { defaultValue: memberFieldset.displayName }
      );
      const translatedFields = memberFieldset.fields.map((field) =>
        Object.assign({}, field, translateField(field, ns))
      );

      return Object.assign({}, fieldset, { displayName, fields: translatedFields });
    }

    return fieldset;
  });

  return Object.assign({}, form, { fieldsets: translatedFieldsets });
}

// Returns a clone of the specified metadata with translations included.
export function translateColumn(column: Metadata.Column, ns: string): Metadata.Column {
  const translatedProps = {} as any;
  if (column.componentType === 'CommandColumn') {
    translatedProps.commands = (column as Metadata.CommandColumn).commands.map((command) =>
      translateCommand(command, ns)
    );
  }

  let translatedDisplayName = undefined;
  if (column.componentType === 'ReferenceColumn') {
    const targetName = (column as Metadata.ReferenceColumn).targetName;
    translatedDisplayName = i18n.t(`metadata:${targetName}.one`, { defaultValue: undefined });
  }

  return Object.assign({}, column, {
    displayName: i18n.t(
      [`metadata:${ns}.properties.${column.name}`, `defaults:properties.${column.name}`],
      { defaultValue: translatedDisplayName || column.displayName }
    ),
    ...translatedProps,
  });
}

// Returns a clone of the specified metadata with translations included.
export function translateCommand(command: Metadata.Command, ns: string): Metadata.Command {
  return Object.assign({}, command, {
    displayName: i18n.t(
      [`metadata:${ns}.commands.${command.name}`, `defaults:commands.${command.name}`],
      { defaultValue: command.displayName }
    ),
  });
}

// Returns a clone of the specified metadata with translations included.
export function translateCollection(
  collection: Metadata.Collection,
  ns: string
): Metadata.Collection {
  const displayCollectionName = i18n.t(`metadata:${ns}.other`, {
    defaultValue: collection.displayCollectionName,
  });
  const translatedProps = {
    commands: collection.commands.map((command) => translateCommand(command, ns)),
  } as any;

  if (['EntityTable', 'Table'].indexOf(collection.componentType) > -1) {
    translatedProps.columns = (collection as Metadata.Table).columns.map((column) =>
      Object.assign({}, column, translateColumn(column, ns))
    );
  }

  return Object.assign({}, collection, {
    displayCollectionName,
    ...translatedProps,
  });
}

// Returns a clone of the specified metadata with translations included.
export function translateEntity(entity: Metadata.Entity): Metadata.Entity {
  const displayName = i18n.t(`metadata:${entity.name}.one`, {
    defaultValue: entity.displayName,
  });
  return Object.assign({}, entity, {
    displayName,
    collection: translateCollection(entity.collection, entity.name),
    form: translateForm(entity.form, entity.name),
    commands: entity.commands.map((command) => translateCommand(command, entity.name)),
  });
}
