import i18n from 'i18next';
import { User } from 'auth';
import { featurePath } from 'routes';
import { Feature, Module, NavigationItem } from 'types';
import { hasRole } from 'auth';

/**
 * Creates nested navigation items for each feature and preserves its order.
 * Grouped features are nested in its group at the position of the first group item.
 * @param module The application module to create navigation items for its features.
 * @param baseUrl The base url used to create navigation links.
 */
export function createModuleNavigation(
  module: Module,
  baseUrl = '',
  user: User
): Array<NavigationItem> {
  if (!module || !module.features || module.features.length === 0) {
    return [];
  }

  // Creates a navigation item based on specified feature
  const createItem = (feature: Feature): NavigationItem => ({
    id: `${module.name}.${feature.group || '_'}.${feature.name}`,
    icon: feature.icon,
    label: i18n.t(`application:modules.${module.name}.features.${feature.name}`, {
      defaultValue: feature.name,
    }),
    href: featurePath(module.name, feature.name, baseUrl),
  });

  // Creates a navigation item based on a group name
  const createGroup = (group: string): NavigationItem => ({
    id: `${module.name}.${group}`,
    label: i18n.t(`application:modules.${module.name}.groups.${group}`, {
      defaultValue: group,
    }),
    children: [],
  });

  // Iterate through features and create navigation items by preserving the original order.
  const items: Array<NavigationItem> = [];
  const groupIndex = new Map<string, number>();
  module.features
    .filter((f) => hasRole(user, f.role))
    .forEach((feature) => {
      if (feature.group) {
        // Add group item if not already done
        if (!groupIndex.has(feature.group)) {
          groupIndex.set(feature.group, items.length);
          items.push(createGroup(feature.group));
        }

        // Add child item to the group
        const index = groupIndex.get(feature.group);
        const children = (index && items[index].children) || [];
        children.push(createItem(feature));
      } else {
        items.push(createItem(feature));
      }
    });

  return items;
}

/**
 * Creates a navigation map. An array of navigation items for each module mapped by name.
 * @param modules An array of modules to create navigation items for.
 * @param baseUrl The base url used to create navigation links.
 */
export function createModuleNavigationMap(
  modules: Array<Module>,
  baseUrl = '',
  user: User
): Map<string, Array<NavigationItem>> {
  const map = new Map<string, Array<NavigationItem>>();
  modules.forEach((module) => map.set(module.name, createModuleNavigation(module, baseUrl, user)));
  return map;
}
