import { lazy } from 'react';
import { Module } from 'types';
import { faFolder, faFolderImage } from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const media: Module = {
  name: 'Media',
  icon: 'image',
  role: 'Creator',
  layout: DefaultLayout,
  features: [
    {
      name: 'Document',
      component: lazy(() => import('modules/Media/Document')),
      icon: faFolder,
    },
    {
      name: 'Image',
      component: lazy(() => import('modules/Media/Image')),
      icon: faFolderImage,
    },
  ],
};

export default media;
