import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppBar, Box, IconButton, Tooltip, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { useAuth } from 'auth';
import { ActionList } from 'components/ActionList';
import { ActionMenuIconButton } from 'components/ActionMenuIconButton';
import { ChangeLanguage } from 'components/ChangeLanguage';
import { Action } from 'types';

interface Props {
  children?: React.ReactNode;
  className?: string;
  hideMenuToggle?: boolean;
  onToggleMenu: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  menuButton: {
    marginRight: 12,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const ApplicationBar: React.FC<Props> = ({
  children,
  className,
  hideMenuToggle,
  onToggleMenu,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('ApplicationBar');
  const { logout } = useAuth();

  const signOut = (): void => {
    logout();
    history.push('/');
  };

  const actionItems: Array<Action> = [
    // {
    //   id: 'messages',
    //   icon: 'Mail',
    //   label: t(`items.messages`),
    // },
    // {
    //   id: 'notifications',
    //   badge: '7',
    //   icon: 'Notifications',
    //   label: t(`items.notifications`),
    // },
    {
      id: 'account',
      icon: 'AccountCircle',
      label: t(`items.account`),
      onClick: () => history.push('/account'),
    },
    {
      id: 'signOut',
      icon: 'ExitToApp',
      label: t(`items.signOut`),
      onClick: signOut,
    },
  ];

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar>
        {!hideMenuToggle && (
          <Tooltip title={String(t('toggle'))}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={onToggleMenu}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        )}
        <Box flexGrow={1} />
        <div>
          <ChangeLanguage />
        </div>
        <div className={classes.sectionDesktop}>
          <ActionList items={actionItems} />
        </div>
        <div className={classes.sectionMobile}>
          <ActionMenuIconButton id="application-bar-menu" items={actionItems} />
        </div>
      </Toolbar>
      <div>{children}</div>
    </AppBar>
  );
};

export default ApplicationBar;
