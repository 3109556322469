import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

interface Props {
  children: NonNullable<React.ReactNode>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    minHeight: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4),
    },
  },
}));

export function DefaultLayout({ children }: Props): React.ReactElement {
  const classes = useStyles();
  return (
    <Container disableGutters maxWidth="xl" className={classes.root}>
      {children}
    </Container>
  );
}

export default DefaultLayout;
