import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageTitle from 'components/PageTitle';
import { createCustomTheme, ThemeConfig } from 'themes';

type Props = ThemeConfig;

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
  },
});

const ThemePreview = (props: Props): React.ReactElement | null => {
  const classes = useStyles();
  const { t } = useTranslation('ThemePreview');

  const previewTheme = createCustomTheme(props);

  return (
    <ThemeProvider theme={previewTheme}>
      <Paper style={{ background: previewTheme.palette.background.default }}>
        <AppBar elevation={0} position="static">
          <Toolbar>
            <Typography variant="h4">Admin UI</Typography>
          </Toolbar>
        </AppBar>
        <Box padding={3}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <PageTitle title={t('title')} overline={t('overline')} />
            </Grid>
            <Grid item className={classes.actions}>
              <Button color="secondary" variant="contained">
                {t('primaryAction')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default ThemePreview;
