import React, { useState } from 'react';
import { Container } from '@material-ui/core';

import CenteredLayout from 'layouts/CenteredLayout';
import ForgotPassword from './ForgotPassword';
import Legal from './Legal';
import Login from './Login';
import TwoFactorAuthentication from './TwoFactorAuthentication';

enum Modes {
  ForgotPassword = 'forgotPassword',
  Login = 'login',
  TwoFactorAuthentication = 'twoFactorAuthentication',
}

const LoginView: React.FC = () => {
  const [mode, setMode] = useState<Modes>(Modes.Login);
  return (
    <CenteredLayout relative>
      <Container maxWidth="sm">
        {mode === Modes.Login && <Login onForgotPassword={() => setMode(Modes.ForgotPassword)} />}
        {mode === Modes.ForgotPassword && (
          <ForgotPassword onBackToLogin={() => setMode(Modes.Login)} />
        )}
        {mode === Modes.TwoFactorAuthentication && (
          <TwoFactorAuthentication onBackToLogin={() => setMode(Modes.Login)} />
        )}
      </Container>
      <Legal />
    </CenteredLayout>
  );
};

export default LoginView;
