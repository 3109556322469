import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Hidden, Link as MuiLink, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  className?: string;
}

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    overflow: 'hidden',
  },
});

const Footer: React.FC<Props> = ({ className }: Props) => {
  const { t } = useTranslation('Footer');
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <Box pb={1} className={clsx(classes.root, className)}>
      <Grid container justifyContent="center" spacing={1}>
        <Hidden smDown>
          <Grid item>
            <Typography variant="caption">
              &copy; {year} Eitido {t('copyright')}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item>
          <MuiLink component={Link} to="/privacy-policy" color="primary" variant="caption">
            {t('privacyPolicy')}
          </MuiLink>
        </Grid>
        <Grid item>
          <MuiLink component={Link} to="/imprint" color="primary" variant="caption">
            {t('imprint')}
          </MuiLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
