import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Icon as MuiIcon } from '@material-ui/core';

interface Props {
  badge?: string;
  badgeColor?: 'primary' | 'secondary' | 'default' | 'error';
  icon?: IconDefinition | string;
}

export function Icon({ badge, badgeColor = 'secondary', icon }: Props): React.ReactElement | null {
  if (!icon) {
    return null;
  }

  if (badge) {
    return (
      <Badge badgeContent={badge} color={badgeColor}>
        {typeof icon === 'string' ? (
          <MuiIcon>{snakeCase(icon)}</MuiIcon>
        ) : (
          <FontAwesomeIcon fixedWidth icon={icon} size="sm" />
        )}
      </Badge>
    );
  }

  return typeof icon === 'string' ? (
    <MuiIcon>{snakeCase(icon)}</MuiIcon>
  ) : (
    <FontAwesomeIcon fixedWidth icon={icon} size="sm" />
  );
}

export default Icon;
