import { useDispatch, useSelector } from 'store';
import { AuthState } from './types';
import { actions, selectAuth } from './slice';

interface State extends AuthState {
  logout: () => void;
}

/**
 * A hook that provides state and functions for authentication and account management.
 * @param name Name of the entity.
 * @returns The translated entity metadata or it throws an error if entity not found.
 */
export function useAuth(): State {
  const dispatch = useDispatch();
  const state = useSelector(selectAuth);
  const logout = (): void => {
    dispatch(actions.logout());
  };

  return { ...state, logout };
}

export default useAuth;
