import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useAuth, useDeleteAccountMutation } from 'auth';
import ConfirmDialog, { ConfirmDialogProps } from 'components/ConfirmDialog';
import Fieldset from 'components/Fieldset';
import { useDialog } from 'hooks/useDialog';
import { downloadBlob } from 'lib/utils';

const Privacy: React.FC = () => {
  const { t } = useTranslation(['account', 'defaults']);
  const { logout, user } = useAuth();
  const dialog = useDialog();
  const [deleteAccountMutation, { isError, isSuccess }] = useDeleteAccountMutation();

  // Handler
  const deleteAccount = (): void => {
    const title = t('Privacy.delete.title');
    const text = t('defaults:dialogs.confirm.text');

    dialog.open<ConfirmDialogProps>(ConfirmDialog, {
      title,
      text,
      onConfirm: () => {
        deleteAccountMutation()
          .unwrap()
          .then(() => setTimeout(logout, 3000));
      },
    });
  };

  const download = (): void => {
    const json = JSON.stringify(user);
    const blob = new Blob([json], { type: 'octet/stream' });
    downloadBlob(blob, 'personal-data.json');
  };

  return (
    <Fieldset title={t('Privacy.title')}>
      <Grid container spacing={3}>
        {isError && (
          <Grid item xs={12} md={12}>
            <Alert severity="error">{t('Privacy.delete.failed')}</Alert>
          </Grid>
        )}
        {isSuccess && (
          <Grid item xs={12} md={12}>
            <Alert severity="success">{t('Privacy.delete.success')}</Alert>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Typography variant="body2">{t('Privacy.description')}</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={download}>
                {t('defaults:commands.download')}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={deleteAccount}>{t('defaults:commands.delete')}</Button>
            </Grid>
            <Grid item>
              <Button href="/privacy-policy">{t('Privacy.policy')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fieldset>
  );
};

export default Privacy;
