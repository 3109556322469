import merge from 'lodash/merge';
import type { Theme, ThemeOptions } from '@material-ui/core';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
// import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { themes } from './themes';
import typography from './typography';

export { themes } from './themes';
export const getThemeNames = (): Array<string> => themes.map((t) => t.name);

export interface ThemeConfig {
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  // Find theme by name
  let themeOptions = themes.find((theme) => theme.name === config.theme);

  // Fallback to default theme
  if (!themeOptions) {
    if (config && config.theme) {
      console.warn(new Error(`The theme ${config.theme} is not valid`));
    }
    themeOptions = themes[0];
  }

  // Remove rounded corners if set
  const configOptions: any = {};
  if (!config.roundedCorners) {
    configOptions.shape = { borderRadius: 0 };
  }

  // Create theme from base options and theme options
  let theme = createTheme(merge({}, baseOptions, themeOptions, configOptions));
  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
