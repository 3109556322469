import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AuthState, Credentials } from './types';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  accessToken: null,
  user: null,
  method: 'Null',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state: AuthState): void {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.user = null;
    },
    setCredentials(state: AuthState, action: PayloadAction<Credentials>): void {
      state.isAuthenticated =
        action.payload.accessToken != undefined && action.payload.user != undefined;
      state.isInitialised = true;
      state.accessToken = action.payload.accessToken || null;
      state.user = action.payload.user || null;
    },
  },
  // extraReducers: (builder) => {
  //   // Add token and user to state after api login was successful.
  //   builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
  //     // Store auth token
  //     if (payload.accessToken) {
  //       setStorageToken(payload.accessToken);
  //       axios.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`;
  //     }

  //     return {
  //       ...state,
  //       isAuthenticated: payload.accessToken != undefined && payload.user != undefined,
  //       isInitialised: true,
  //       accessToken: payload.accessToken,
  //       user: payload.user,
  //     };
  //   });
  // },
});

export const { actions, reducer } = slice;

// Selectors
export const selectAuth = createSelector(
  (state: RootState) => state.auth,
  (auth) => auth
);

export const selectIsAuthenticated = createSelector(
  (state: RootState) => state.auth.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);

export const selectIsInitialised = createSelector(
  (state: RootState) => state.auth.isInitialised,
  (isInitialised) => isInitialised
);

export const selectAccessToken = createSelector(
  (state: RootState) => state.auth.accessToken,
  (accessToken) => accessToken
);

export const selectUser = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user
);
