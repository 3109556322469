import clsx from 'clsx';
import snakeCase from 'lodash/snakeCase';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Icon } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { NavigationItem as INavigationItem } from 'types';
import { Navigation } from './Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    color: alpha(theme.palette.primary.contrastText, 0.75),
    borderRadius: '0',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: alpha(theme.palette.primary.light, 0.25),
    },
  },
  selected: {
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: alpha(theme.palette.primary.light, 0.25),
  },
  startIcon: {
    marginLeft: 0,
    width: theme.spacing(4),
  },
  noIcon: {
    paddingLeft: theme.spacing(5),
  },
  label: {
    marginRight: 'auto',
  },
  level0: {
    paddingLeft: theme.spacing(3),
  },
  level1: {
    paddingLeft: theme.spacing(6),
    fontWeight: 400,
    color: alpha(theme.palette.primary.contrastText, 0.5),
  },
  level2: {
    paddingLeft: theme.spacing(9),
  },
  level3: {
    paddingLeft: theme.spacing(12),
  },
}));

type LevelName = 'level0' | 'level1' | 'level2' | 'level3';

interface NavigationItemProps {
  className?: string;
  item: INavigationItem;
  level?: number;
}

export const NavigationItem: React.FC<NavigationItemProps> = ({
  className,
  item,
  level = 0,
}: NavigationItemProps): React.ReactElement => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const isSelected = item.href === pathname;
  const levelClassName: LevelName = `level${Math.min(Math.max(level, 0), 3)}` as LevelName;
  const startIcon =
    typeof item.icon === 'string' ? (
      <Icon>{snakeCase(item.icon)}</Icon>
    ) : (
      item.icon && <FontAwesomeIcon fixedWidth icon={item.icon} />
    );

  return (
    <>
      <Button
        classes={{
          startIcon: item.icon && classes.startIcon,
          label: item.icon ? classes.label : classes.noIcon,
        }}
        className={clsx(
          classes.root,
          classes[levelClassName],
          { [classes.selected]: isSelected },
          className
        )}
        component={item.href ? RouterLink : 'span'}
        to={item.href || ''}
        startIcon={startIcon}
        onClick={handleToggle}
      >
        <span className={classes.label}>{item.label}</span>
        {item.children &&
          item.children.length > 0 &&
          (open ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
      </Button>
      <Collapse in={open}>
        {item.children && item.children.length > 0 && (
          <Navigation items={item.children} level={level + 1} />
        )}
      </Collapse>
    </>
  );
};

NavigationItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.any.isRequired,
};

export default NavigationItem;
