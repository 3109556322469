import { colors } from '@material-ui/core';
import { smoothShadows, softShadows } from './shadows';
import typography from './typography';

const overrides = {
  /* Start: Label on top of input fields */
  /* NOTE: This override currently works with Material UI ^v4.0 and for outlined inputs only */
  MuiFormControl: {
    root: { paddingTop: '23px' },
  },
  MuiSelect: {
    root: {
      paddingTop: '11.5px',
      paddingBottom: '11.5px',
    },
  },
  MuiTextField: {
    root: { paddingTop: '28px' },
  },
  MuiInputBase: {
    input: {
      lineHeight: '1.4em',
      // Always show placeholder
      'label[data-shrink=false] + $formControl &': {
        '&::-webkit-input-placeholder': { opacity: 0.5 },
        '&::-moz-placeholder': { opacity: 0.5 },
        '&:-ms-input-placeholder': { opacity: 0.5 },
        '&::-ms-input-placeholder': { opacity: 0.5 },
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      ...typography.h6,
      transform: 'translate(0px, 0px) scale(1)',
      '&$marginDense': {
        transform: 'translate(0px, 0px) scale(1)',
      },
      '&$shrink': {
        transform: 'translate(0px, 0px) scale(1)',
      },
    },
  },
  PrivateNotchedOutline: {
    legendLabelled: {
      display: 'none',
    },
  },
  /* End: Label on top of input fields */
  MuiIconButton: {
    edgeEnd: {
      marginRight: '-8px',
    },
  },
};

export const themes = [
  {
    name: 'blueberry',
    overrides,
    palette: {
      background: {
        default: '#f2f7fa',
        paper: colors.common.white,
      },
      primary: {
        main: '#0b2555',
        contrastText: '#fff',
      },
      secondary: {
        main: '#10a1fd',
        contrastText: '#fff',
      },
    },
    shape: {
      borderRadius: 2,
    },
    shadows: smoothShadows,
  },
  {
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=01579b&secondary.color=0277BD
    name: 'cloud',
    overrides,
    palette: {
      primary: {
        light: '#4f83cc',
        main: '#01579b',
        dark: '#002f6c',
        contrastText: '#fff',
      },
      secondary: {
        light: '#58a5f0',
        main: '#0277bc',
        dark: '#004c8b',
        contrastText: '#fff',
      },
    },
    shadows: smoothShadows,
  },
  {
    name: 'indigo',
    overrides,
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: colors.indigo[600],
      },
      secondary: {
        main: '#5850EC',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: 'nostalgia',
    overrides: {
      MuiTableCell: {
        stickyHeader: { backgroundColor: '#f0f2f580' },
      },
      ...overrides,
    },
    palette: {
      background: { default: '#f0f2f5' },
      primary: {
        light: '#575c64',
        main: '#2e333a',
        dark: '#040c14',
        contrastText: '#dadfe5',
      },
      secondary: {
        light: '#78fbc6',
        main: '#3fc795',
        dark: '#009567',
        contrastText: '#fff',
      },
    },
    shadows: softShadows,
  },
  {
    name: 'spark',
    overrides,
    palette: {
      background: { default: '#f4f7fc' },
      primary: {
        light: '#4c67a6',
        main: '#153d77',
        dark: '#00184b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffa270',
        main: '#ff7043',
        dark: '#c63f17',
        contrastText: '#fff',
      },
    },
    shadows: softShadows,
  },
];
