import { errorPath } from './path';
import { ErrorRoute } from './Route';
import NotFound from 'views/NotFound';

export const errorRoutes: Array<ErrorRoute> = [
  {
    exact: true,
    path: errorPath(404),
    title: 'Error 404',
    component: NotFound,
    context: {
      error: '404',
    },
  },
];
