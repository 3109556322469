import Joi from 'joi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Grid, TextField } from '@material-ui/core';

import {
  defaultPasswordRequirements,
  useChangePasswordMutation,
  useGetPasswordRequirementsQuery,
} from 'auth';
import Fieldset from 'components/Fieldset';
import PasswordField from 'components/PasswordField';
import SubmitButton from 'components/SubmitButton';
import SubmitStateAlert from 'components/SubmitStateAlert';

const validationSchema = Joi.object({
  password: Joi.string().max(64).required(),
  newPassword: Joi.string().min(8).max(64).required(),
  confirmNewPassword: Joi.string().min(8).max(64).required().valid(Joi.ref('newPassword')),
});

const Security: React.FC = () => {
  const { t } = useTranslation(['account', 'messages']);
  const { control, handleSubmit, formState, reset } = useForm<any>({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
  });
  const [changePassword, { error, isError, isLoading, isSuccess }] = useChangePasswordMutation();
  const {
    data: passwordRequirements = defaultPasswordRequirements,
    isError: passwordRequirementsIsError,
  } = useGetPasswordRequirementsQuery();

  // Show console error when getPasswordRequirements failed
  useEffect(
    () =>
      (passwordRequirementsIsError && console.error('Password requirements not available.')) ||
      void 0,
    [passwordRequirementsIsError]
  );

  // Reset form after successful submit
  useEffect(() => (isSuccess && reset()) || void 0, [isSuccess, reset]);

  // Handler
  const submit = (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    handleSubmit(changePassword)(e);

  return (
    <form onSubmit={submit} noValidate>
      <Fieldset title={t('Security.title')}>
        <Grid container spacing={3}>
          <SubmitStateAlert
            grid={12}
            error={
              error && 'status' in error && error.status === 401
                ? t('Security.errors.invalidPassword')
                : undefined
            }
            isError={isError}
            isInvalid={formState.errors.length > 0}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
          <Grid item xs={12} md={12}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  type="password"
                  label={t('Security.properties.password')}
                  helperText={t('Security.propertyDescriptions.password', { defaultValue: '' })}
                  error={fieldState.invalid}
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 64 }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <PasswordField
              name="newPassword"
              control={control}
              label={t('Security.properties.newPassword')}
              helperText={t('Security.propertyDescriptions.newPassword', { defaultValue: '' })}
              maxLength={64}
              requirements={passwordRequirements}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="confirmNewPassword"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  type="password"
                  label={t('Security.properties.confirmNewPassword')}
                  helperText={t('Security.propertyDescriptions.confirmNewPassword', {
                    defaultValue: '',
                  })}
                  error={fieldState.invalid}
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 64 }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SubmitButton
              color={formState.isDirty && formState.isValid ? 'primary' : 'default'}
              inProgress={isLoading}
            />
          </Grid>
        </Grid>
      </Fieldset>
    </form>
  );
};

export default Security;
