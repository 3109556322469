import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ConfigurationState {
  apiEndpoint: string;
  auth?: { username?: string; password?: string; email?: string };
  authEndpoint: string;
  isDevelopment: boolean;
  googleMapsKey: string;
  mediaUrl: string;
  uploadPaths: { [index: string]: string };
  uploadMaxFileSize: number;
}

/**
 * Initial configuration state.
 * Be careful working directly with the initial state.
 * It should be used only before the store is available.
 */
export const initialConfiguration: ConfigurationState = {
  apiEndpoint:
    process.env.REACT_APP_API_ENDPOINT ||
    (window
      ? `${window.location.protocol}//${window.location.hostname}/api`
      : 'http://localhost/api'),
  auth:
    process.env.NODE_ENV === 'development'
      ? {
          username: process.env.REACT_APP_USER_NAME,
          password: process.env.REACT_APP_USER_PASSWORD,
          email: process.env.REACT_APP_USER_EMAIL,
        }
      : undefined,
  authEndpoint:
    process.env.REACT_APP_AUTH_ENDPOINT ||
    (window
      ? `${window.location.protocol}//${window.location.hostname}/account`
      : 'http://localhost/account'),
  isDevelopment: process.env.NODE_ENV === 'development',
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  mediaUrl:
    process.env.REACT_APP_MEDIA_URL ||
    (window
      ? `${window.location.protocol}//${window.location.hostname}/media`
      : 'http://localhost/media'),
  uploadPaths: { document: '/upload/documents', image: '/upload/images' },
  uploadMaxFileSize: 31457280,
};

// Slice
const slice = createSlice({
  name: 'configuration',
  initialState: initialConfiguration,
  reducers: {
    set(state: ConfigurationState, action: PayloadAction<ConfigurationState>): void {
      state = action.payload;
    },
  },
});

export const { reducer } = slice;

// Selectors
export const selectConfiguration = createSelector(
  (state: RootState) => state.configuration,
  (configuration) => configuration
);
