import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, NoSsr, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  language: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
  button: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export const ChangeLanguage = (): React.ReactElement => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('ChangeLanguage');
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleOpen = (): void => setMenuOpen(true);
  const handleClose = (): void => setMenuOpen(false);

  const languages = (i18n.options.supportedLngs || [i18n.language]).filter((l) => l !== 'cimode');

  return (
    <>
      <Tooltip title={String(t('changeLanguage'))} enterDelay={300}>
        <Button
          className={classes.button}
          color="inherit"
          aria-owns={menuOpen ? 'language-menu' : undefined}
          aria-haspopup="true"
          data-ga-event-category="header"
          data-ga-event-action="language"
          onClick={handleOpen}
          ref={anchorEl}
        >
          <LanguageIcon />
          <span className={classes.language}>{t(`languages.${i18n.language}`)}</span>
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </Tooltip>
      <NoSsr defer>
        <Menu id="language-menu" anchorEl={anchorEl.current} open={menuOpen} onClose={handleClose}>
          {languages.map((language) => (
            <MenuItem
              component="a"
              data-no-link="true"
              key={language}
              selected={language === i18n.language}
              lang={language}
              hrefLang={language}
              onClick={() => {
                i18n.changeLanguage(language);
                handleClose();
              }}
            >
              {t(`languages.${language}`)}
            </MenuItem>
          ))}
        </Menu>
      </NoSsr>
    </>
  );
};

export default ChangeLanguage;
