import dayjs from 'dayjs';
import i18n from 'i18n';

/** Format bytes to string with fixed decimals and closest unit. */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes == 0) return '0 Bytes';
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  const num = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  return new Intl.NumberFormat(i18n.language).format(num) + ' ' + sizes[i];
}

/** Format date to string. */
export function formatDate(date: Date | string, options?: Intl.DateTimeFormatOptions): string {
  return new Intl.DateTimeFormat(i18n.language, options).format(dayjs(date).toDate());
}

/**
 * Splices text within a string.
 * @param {string} originalText The original text
 * @param {string} text The text to insert
 * @param {int} offset The position to insert the text at (before)
 * @param {int} [removeCount=0] An optional number of characters to overwrite
 * @returns {string} A modified string containing the spliced text.
 */
export function splice(originalText = '', text: string, offset: number, removeCount = 0): string {
  const calculatedOffset = offset < 0 ? originalText.length + offset : offset;
  return (
    originalText.substring(0, calculatedOffset) +
    text +
    originalText.substring(calculatedOffset + removeCount)
  );
}

/**
 * Given an array of requestsToMake and a limit on the number of max parallel requests
 * queue up those requests and start firing them.
 * @remarks
 * Inspired by Rafael Xavier's approach here: https://stackoverflow.com/a/48007240/761388
 * @param requestsToMake
 * @param maxParallelRequests the maximum number of requests to make - defaults to 6
 */
export type RequestToMake = () => Promise<any>;
export async function throttleRequests(
  requestsToMake: RequestToMake[],
  maxParallelRequests = 6
): Promise<Array<any>> {
  const responses: Array<any> = [];

  // queue up simultaneous calls
  const queue: Promise<any>[] = [];
  for (const requestToMake of requestsToMake) {
    // fire the async function, add its promise to the queue,
    // and remove it from queue when complete
    const promise = requestToMake().then((res) => {
      queue.splice(queue.indexOf(promise), 1);
      return res;
    });
    queue.push(promise);

    // if the number of queued requests matches our limit then
    // wait for one to finish before enqueueing more
    if (queue.length >= maxParallelRequests) {
      responses.push(await Promise.race(queue));
    }
  }
  // wait for the rest of the calls to finish
  return Promise.all(queue).then((res: Array<any>) => responses.concat(res));
}

/** Downloads the specified blob as file in a browser. */
export function downloadBlob(blob: Blob, filename: string): void {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}
