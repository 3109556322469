import { lazy } from 'react';
import { Module } from 'types';
import { faMessages, faMoneyCheckDollarPen, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const sales: Module = {
  name: 'Sales',
  icon: 'person',
  role: 'Sale',
  layout: DefaultLayout,
  features: [
    {
      name: 'MailboxMessage',
      component: lazy(() => import('modules/Sales/MailboxMessage')),
      icon: faMessages,
    },
    {
      name: 'Customer',
      component: lazy(() => import('modules/Sales/Customer')),
      icon: faUserGroup,
    },
    {
      name: 'Invoice',
      component: lazy(() => import('modules/Sales/Invoice')),
      icon: faMoneyCheckDollarPen,
      role: 'SaleNext',
    },
    // {
    //   name: 'Address',
    //   group: 'person',
    //   component: lazy(() => import('modules/Sales/Address')),
    // },
    // {
    //   name: 'Contact',
    //   group: 'person',
    //   component: lazy(() => import('modules/Sales/Contact')),
    // },
    {
      name: 'AddressType',
      group: 'configuration',
      component: lazy(() => import('modules/Sales/AddressType')),
    },
    {
      name: 'ContactType',
      group: 'configuration',
      component: lazy(() => import('modules/Sales/ContactType')),
    },
    {
      name: 'CustomerType',
      group: 'configuration',
      component: lazy(() => import('modules/Sales/CustomerType')),
    },
    {
      name: 'MailboxAccount',
      group: 'configuration',
      component: lazy(() => import('modules/Sales/MailboxAccount')),
    },
  ],
};

export default sales;
