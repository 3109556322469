import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';

interface PageTitleProps {
  children?: React.ReactNode;
  title: string;
}

export const Fieldset: React.FC<PageTitleProps> = ({ children, title }: PageTitleProps) => {
  return (
    <Card>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <Box pt={2}>{children}</Box>
      </CardContent>
    </Card>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default Fieldset;
