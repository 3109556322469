import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { api } from './api';
import { ApiSchema } from './types';

// State
const initialState: ApiSchema = {};

// Slice
const slice = createSlice({
  name: 'schema',
  initialState,
  reducers: {
    set(state: ApiSchema, action: PayloadAction<ApiSchema>): void {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Set schema after api fetch was successful.
    builder.addMatcher(api.endpoints.getSchema.matchFulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export const { reducer } = slice;

// Selectors
export const selectSchema = createSelector(
  (state: RootState) => state.schema,
  (schema) => schema
);
